<template>
  <div class="professionalinvestigation">
    <div class="minw">
      <div class="screen" ref="screenh">
        <div class="occupaTitle">
          职业分类
        </div>

        <div style="border-bottom: 2px solid #12B098;height: 2px;margin-top: -2px;margin-bottom: 15px;"></div>
        <div class="condition" style="min-height:40px">
          <div style="flex: 47; position: relative">
            <p>
              <span @click="active(itemitem)" v-for="(itemitem, indexitem) in screenarr" :key="indexitem" :class="{
  active: itemitem.id == id
            }">{{ itemitem.jobName }}</span>
            </p>
          </div>
        </div>
      </div>
      <div class="wrap">
        <div class="wrap_left">
          <div class="condition" style="align-items: center;display: flex;width: 80%;">
            <div class="searchTitle" style="flex:2">
              共匹配到 <span style="color: #12B098;">{{ totalNum || '---'}}</span> 条数据
            </div>
            <div class="searchInput">
              <el-input placeholder="请输入职业名称" v-model.trim="careerName" @keyup.enter.native="sousuo" clearable></el-input>
              <div class="divicon" @click="sousuo()">
                <i class="el-icon-search" style="color: white;"></i>
              </div>
            </div>
          </div>
          <div class="bottom" v-if="professionList.length == 0" v-loading="loading">
            <img src="../../assets/imgs/nodata.png" style="display:block;margin:0 auto" alt />
          </div>
          <div class="bottom" ref="DOM" v-loading="loading" v-else>
            <div class="professionCss" v-for="(item, index) in professionList" :key="index">
              <div>
                <!-- 二级 -->
                <h1 style="margin-bottom: 13px;">{{ item.jobName }}</h1>
              </div>
              <div v-for="(itemm, indexx) in item.children" class="secondMajor inlineCss" :key="indexx">
                <!-- 三级 -->
                <div @click="goprofessionDetail(itemm)">{{ itemm.jobName }}</div>
                <!-- <div v-for="(itemmm, indexxx) in itemm.children
" :title="itemmm.jobName" :key="indexxx" class="professionItem inlineCss">
                  {{ itemmm.jobName }}

                </div> -->

              </div>
            </div>

          </div>
        </div>
        <!-- <div class="wrap_right">
          <img src="../../assets/imgs/schoolright.png" alt="">

        </div> -->
      </div>

    </div>
    <!-- 回到顶部 -->
    <div style="position:fixed;right:10%;bottom:10%;z-index:9999" v-if="scrollShow" @click="goTop">
      <el-tooltip class="item" effect="dark" content="回到顶部" placement="left">
        <i class="el-icon-caret-top" style="font-size:25px;cursor:pointer"></i>
      </el-tooltip>
    </div>
  </div>
</template>

<script>
import { queryJobName, queryCareer } from '@/api/professionalinvestigation.js'
export default {
  name: 'professionalinvestigation',
  components: {},
  props: {},
  data() {
    return {
      scrollShow: false,
      loading: true,
      menleiflag: false,
      screenarr: [],
      id: '',
      careerName: '',
      professionList: [],
      totalNum: 0,
    }
  },
  computed: {},
  watch: {

  },
  created() {
    this.getsubjectCategories()
  },
  mounted() {
    window.addEventListener('load', () => { this.$router.replace({ query: {} }) })
    window.addEventListener('scroll', this.handleScroll) // 监听页面滚动
  },
  methods: {
    handleScroll() {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
      if (scrollTop >= 1750) {
        this.scrollShow = true
      } else {
        this.scrollShow = false
      }
    },
    goTop() {
      // document.documentElement.scrollTop = 0
      document.body.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'start'
      })
    },
    // 获取职业门类
    getsubjectCategories() {
      queryJobName({ id: 0 }).then(res => {
        this.screenarr = res.data
        this.active(res.data[0])
      })
    },

    active(item) {
      this.id = item.id
      this.careerName  = ''
      this.getByProfessionalMajor()
      this.loading = true;
    },
    getByProfessionalMajor() {
      queryJobName({ id: this.id }).then(res => {
        this.professionList = res.data.filter(item => item.id == this.id)[0].children;
        this.totalNum = 0;
        this.professionList.forEach(item => {
          this.totalNum += item.children.length
        })
        this.loading = false;
      })
    },
    sousuo() {
      if (!this.careerName){
          this.getByProfessionalMajor()
          return
      }
      queryCareer({ careerName: this.careerName }).then(res => {
        if (res.data.list.length == 0) {
          this.$alert('暂无匹配数据', '', {
            confirmButtonText: '确定',
          });
        } else {
          let arr = [];
          res.data.resultList.forEach(item => {
            for (const key in item) {
              let children = [];
              children.push(item[key][2])
              let obj = {
                jobName: item[key][1].jobName,
                id: item[key][1].id,
                children,
              }
              arr.push(obj)
            }
          })
          arr.forEach((item, index) => {
            arr.forEach((itemSecode) => {
              if (item.jobName == itemSecode.jobName) {
                item.children.push(itemSecode.children[0])
              }
            })
          })
          // 通过jobname进行去重
          const unique = [];
          for (const item of arr) {
            const duplicate = unique.find(
              (obj) => obj.jobName === item.jobName
            );
            if (!duplicate) {
              unique.push(item);
            }
          }
           unique.forEach(item=>{
            item.children.shift()
           })
            this.professionList = unique
            this.totalNum = 0;
            this.professionList.forEach(item => {
              this.totalNum += item.children.length
            })
        }
      })

    },
    goprofessionDetail(itemm) {
      this.$router.push({
        path: '/occupationDetail',
        query: { id: itemm.id }
      })
    }

  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll)
  },


}
</script>

<style scoped lang="scss">
.professionalinvestigation {
  box-sizing: border-box;
  width: 100%;
  // background-color: #fcfbf9;
  padding: 0px 0 80px 0;

  .searchInput {
    // width: 627px;
    flex: 6;
  }

  .screen {
    box-sizing: border-box;
    // padding: 35px 30px;
    width: 100%;
    // border: 1px solid #f4f4f4;
    // border-radius: 10px;
    background: white;
    user-select: none;
    .occupaTitle {
      width: 202px;
      height: 40px;
      background: #12b098;
      text-align: center;
      line-height: 40px;
      color: white;
      font-size: 16px;
    }
    .condition {
      display: flex;
      flex-direction: row;
      margin-bottom: 8px;

      .searchTitle {
        font-size: 16px;
        color: #000000;
        margin-right: 15px;
        flex: 3.5;
        line-height: 25px;
      }

      .active {
        background: #12b098;
        border-radius: 8px;
        color: white;
      }

      .active:hover {
        color: white;
      }

      span {
        cursor: pointer;
        display: inline-block;
        margin-right: 9px;
        margin-bottom: 10px;
        font-size: 16px;
        font-weight: 400;
        color: #5f5f5f;
        box-sizing: border-box;
        padding: 3px 8px;
        height: 30px;
        line-height: 24px;

      }

      span:hover {
        color: #12b098;
      }
    }
  }

  .wrap {
    display: flex;

    .wrap_left {
      flex: 1;
      margin-right: 74px;
    }

    .wrap_right {
      width: 300px;
    }
  }

  .bottom {
    box-sizing: border-box;
    min-height: 800px;
    width: 100%;
    // margin-top: 20px;
    // padding: 35px 35px 20px 35px;
    // border: 1px solid #f4f4f4;
    // border-radius: 10px;
    background: white;

    .professionCss {
      border-bottom: 2px solid #f4f4f4;
      padding-bottom: 3px;
      margin-bottom: 20px;

      h1 {
        display: inline-block;
        font-size: 16px;
        font-weight: bold;
        color: #30c9b2;
      }

      .secondMajor {
        font-size: 16px;
        cursor: pointer;
        display: inline-block;
        width: 20%;
        color: #686868;
        margin-bottom: 13px;
        padding-left: 10px;
        box-sizing: border-box;
      }
      .secondMajor:hover {
        color: #12b098;
      }

      .professionItem {
        font-size: 16px;
        cursor: pointer;
        display: inline-block;
        width: 20%;
        color: #686868;
        margin-bottom: 13px;

        span:nth-of-type(1) {
          display: inline-block;
        }

        span:nth-of-type(1):hover {
          color: #30c9b2;
        }
      }

      .professionItem:hover {
        color: #30c9b2;
      }
    }
  }
}
</style>
